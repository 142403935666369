<template>
  <b-card title="Select a Asset">
    <b-col cols="12">
      <b-form-group>
        <v-select
          v-model="selected"
          :dir="'ltr'"
          label="label"
          :options="orgData"
          class="select-size-lg"
        />
      </b-form-group>
    </b-col>

    <b-card-text class="mt-1">
      <strong>Selected asset info: </strong>
    </b-card-text>
    <b-card>
      <b-row
        v-if="selected"
        class="mb-2"
      >
        <b-col
          md="3"
          class="mb-1"
        >
          <strong>Asset : </strong>{{ selected.assetID }}
        </b-col>
        <b-col
          md="3"
          class="mb-1"
        >
          <strong>Device : </strong>{{ selected.deviceID }}
        </b-col>
        <b-col
          md="3"
          class="mb-1"
        >
          <strong>Last Reported : </strong>{{ convertDate(selected.lastUpdatedTime) }}
        </b-col>
        <b-col
          md="3"
          class="mb-1"
        >
          <strong>Status : </strong>
          <feather-icon
            class="mr-1"
            :class="connectedVariant(selected.lastUpdatedTime) ? 'text-success' : 'text-danger' "
            :fill="connectedVariant(selected.lastUpdatedTime) ? '#28c76f' : '#ea5455' "
            icon="CircleIcon"
            size="16"
          />
        </b-col>
      </b-row>

    </b-card>
  </b-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import {
  BCard, BCardText, BAvatar, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { msEpochToDate, isConnected } from '@core/utils/filter'
import assetFactoryStore from '../../../dashboard/factory/assetFactoryStore'

export default {
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-assets'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetFactoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  components: {
    BCard,
    BCardText,
    BAvatar,
    BCol,
    BRow,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      orgData: [],
      selected: null,
    }
  },
  watch: {
    selected() {
      this.$emit('onSelect', this.selected)
    },
  },
  created() {
    this.fetchAllAssets()
  },
  methods: {
    fetchAllAssets() {
      store.dispatch('app-assets/fetchAllAssets')
        .then(response => {
          const result = response.data
          this.orgData = result.map(item => {
            // eslint-disable-next-line no-param-reassign
            item.label = `${item.assetID} - ${item.name} - ${item.lineName}`
            return item
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Assets',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    statusVariant(status) {
      const statusColor = ['text-danger', 'text-success', 'text-warning', 'text-success']
      return statusColor[status]
    },
    statusFillVariant(status) {
      const statusColor = ['#FF0000', '#08E1AD', '#FF5F1F', '#08E1AD']
      return statusColor[status]
    },
    convertDate(val) {
      return msEpochToDate(val)
    },
    connectedVariant(val) {
      return isConnected(val)
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
