<template>
  <b-card
    class="device-swap"
    no-body
  >
    <b-row>
      <b-col cols="12">
        <device-swap-asset
          @onSelect="onSelectAsset"
        />
        <device-swap-device
          @onSelect="onSelectDevice"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class=" d-flex justify-content-center ">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            size="lg"
            :disabled="isSubmitting"
            @click="swapTwin"
          >
            <span v-if="!isSubmitting">Swap</span>
            <b-spinner
              v-if="isSubmitting"
              class="mr-1"
              variant="white"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow, BCol, BCard, BButton, BSpinner,
}
from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeviceSwapAsset from './DeviceSwapAsset.vue'
import DeviceSwapDevice from './DeviceSwapDevice.vue'
import deviceStoreModule from '../deviceStoreModule'

export default {
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-device'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, deviceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    DeviceSwapAsset,
    DeviceSwapDevice,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedAsset: null,
      selectedDevice: null,
      isSubmitting: false,
      existingTwin: null,
    }
  },
  methods: {
    onSelectAsset(asset) {
      this.selectedAsset = asset
    },
    onSelectDevice(device) {
      this.selectedDevice = device
    },
    swapTwin() {
      this.isSubmitting = true
      if (this.selectedAsset && this.selectedDevice) {
        this.getOldTwinAndSwap()
      } else {
        this.isSubmitting = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select Asset and Device',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    getOldTwinAndSwap() {
      store.dispatch('app-device/getTwin', this.selectedAsset.assetID)
        .then(response => {
          this.existingTwin = response.data
          this.newTwin = { ...this.existingTwin }
          this.saveNewTwin()
        })
        .catch(() => {
          this.isSubmitting = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Retrieving Twin Information',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveNewTwin() {
      this.newTwin.reasonForChange = 'Swap Device'
      this.newTwin.newDeviceID = this.selectedDevice.deviceID
      console.log(this.newTwin)
      store.dispatch('app-device/swapDevice', this.newTwin)
        .then(() => {
          this.isSubmitting = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Assigned new device successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.isSubmitting = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Swapping Device',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
