import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDevices() {
      return new Promise((resolve, reject) => {
        axios
          .get('/device/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDevicesByStatus(ctx, status) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/?status=${status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDevice(ctx, device) {
      return new Promise((resolve, reject) => {
        axios
          .post('/device/', device)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDevice(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/?deviceID=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTwin(ctx, assetID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/twin?assetID=${assetID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    swapDevice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('device/swapDevice', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
