<template>
  <b-card title="Select a Device">
    <b-row>
      <b-col md="11">
        <b-form-group>
          <v-select
            v-model="selected"
            :dir="'ltr'"
            label="deviceID"
            :options="orgData"
            class="select-size-lg"
            @input="getSelectedDeviceInfo"
          >
            <template #option="{ deviceID, lastUpdatedTime }">
              <feather-icon
                class="mr-1"
                :class="connectedVariant(lastUpdatedTime) ? 'text-success' : 'text-danger' "
                :fill="connectedVariant(lastUpdatedTime) ? '#28c76f' : '#ea5455' "
                icon="CircleIcon"
                size="16"
              />
              <span class="text-capitalize">{{ deviceID }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        md="1"
        class="d-flex justify-content-start"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          @click="openQRCodeScanner=!openQRCodeScanner"
        >
          <feather-icon
            icon="CameraIcon"
            size="25"
          />
        </b-button>
      </b-col>
    </b-row>
    <qrcode-stream
      v-if="openQRCodeScanner"
      @decode="onDecode"
    />
    <b-card-text class="mt-1">
      <strong>Selected Device info:</strong>
    </b-card-text>
    <b-card>
      <b-row
        v-if="selected"
        class="mb-2"
      >
        <b-col
          md="3"
          class="mb-1"
        >
          <strong>Asset : </strong>{{ selected.assetID }}
        </b-col>
        <b-col
          md="3"
          class="mb-1"
        >
          <strong>Device : </strong>{{ selected.deviceID }}
        </b-col>
        <b-col
          md="3"
          class="mb-1"
        >
          <strong>Last Reported : </strong>{{ convertDate(selected.lastUpdatedTime) }}
        </b-col>
        <b-col
          md="3"
          class="mb-1"
        >
          <strong>Status : </strong>
          <feather-icon
            class="mr-1"
            :class="connectedVariant(selected.lastUpdatedTime) ? 'text-success' : 'text-danger' "
            :fill="connectedVariant(selected.lastUpdatedTime) ? '#28c76f' : '#ea5455' "
            icon="CircleIcon"
            size="16"
          />
        </b-col>
      </b-row>

    </b-card>
  </b-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import {
  BCard, BCardText, BAvatar, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { QrcodeStream } from 'vue-qrcode-reader'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { msEpochToDate, isConnected } from '@core/utils/filter'
import { onUnmounted } from '@vue/composition-api'
import deviceStoreModule from '../deviceStoreModule'

export default {
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-device'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, deviceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  components: {
    BCard,
    BCardText,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BButton,
    QrcodeStream,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      orgData: [],
      selected: null,
      openQRCodeScanner: false,
      timerNewtDeviceInfo: null,
    }
  },
  watch: {
    selected() {
      this.$emit('onSelect', this.selected)
    },
  },
  created() {
    this.fetchAllDevices()
  },
  beforeDestroy() {
    if (this.timerNewtDeviceInfo) {
      clearInterval(this.timerNewtDeviceInfo)
      window.clearInterval(this.timerNewtDeviceInfo)
      clearTimeout(this.timerNewtDeviceInfo)
    }
  },
  methods: {
    fetchAllDevices() {
      store.dispatch('app-device/fetchDevicesByStatus', 'tested')
        .then(response => {
          this.orgData = response.data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Device',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchThisDevice() {
      store.dispatch('app-device/fetchDevice', this.selected.deviceID)
        .then(response => {
          // eslint-disable-next-line prefer-destructuring
          this.selected = response.data[0]
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Device Status',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getSelectedDeviceInfo() {
      this.fetchThisDevice()
      if (this.timerNewtDeviceInfo != null) {
        clearInterval(this.timerNewtDeviceInfo)
      }
      this.timerNewtDeviceInfo = window.setInterval(() => {
        setTimeout(this.fetchThisDevice(), 0)
      }, 1000 * 5)
    },
    onDecode(decodedString) {
      this.openQRCodeScanner = false
      this.selected = this.orgData.find(device => device.deviceID === decodedString)
      this.getSelectedDeviceInfo()
    },
    connectedVariant(val) {
      return isConnected(val)
    },
    convertDate(val) {
      return msEpochToDate(val)
    },
  },
}
</script>
  <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
  </style>
